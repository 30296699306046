import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import { FCM } from "@capacitor-community/fcm";
import { Platform } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import { environment } from 'src/environments/environment';
import { map, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { MyProfileService } from './my-profile.service';

const apiURLs = {
  root: environment.restApiUrl,
  getNotificationSettings: '/client/mobile/subscribe/',
  setNotificationSettings: '/client/mobile/subscribe/'
}

export type NotificationSetting = {
    general: boolean,
    reminders: boolean
}
export type NotificationSettingType = 'general' | 'reminders';

@Injectable({
  providedIn: 'root'
})
export class FcmService {
  private notificationSettings: BehaviorSubject<NotificationSetting> = new BehaviorSubject<NotificationSetting>({general: false, reminders: false});

  constructor(
    private router: Router,
    private platform: Platform,
    private ngZone: NgZone,
    private http: HttpClient,
    private myProfileService: MyProfileService,
  ) { }

  initPush() {
    if (this.platform.is('capacitor')) {
      this.registerPush();
    }
    else {
      //only for web to at least get the settings data on init push
      this.getNotificationSettings();
    }
  }

  private registerPush() {
    PushNotifications.requestPermissions().then((permission) => {
      if (permission.receive == "granted") {
        this.getNotificationSettings();
        // Register with Apple / Google to receive push via APNS/FCM
        if(Capacitor.getPlatform() == 'ios'){
          PushNotifications.register().then((res)=>{
            console.log('From Regisiter Promise', res)
          })
          PushNotifications.addListener('registration', (token: Token)=>{            
            FCM.getToken().then((result) => {
              // alert(`Firebase Token ${result.token}`);
              console.log('Firebase Token: ', result.token);
            }).catch((err) => console.log('i am Error' , err));
          })
        }else{
          FCM.getToken().then((result) => {
            // alert(`Firebase Token ${result.token}`);
            console.log('Firebase Token: ', result.token);
          }).catch((err) => console.log('i am Error' , err));
        }
      } else {
        // No permission for push granted
        // alert('No Permission for Notifications!')
        this.setNotificationSettings({general: false, reminders: false});
      }
    });
 
    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error: ' + JSON.stringify(error));
    });
 
    PushNotifications.addListener('pushNotificationReceived',
      async (notification: PushNotificationSchema) => {
        console.log('Push received: ' + JSON.stringify(notification));
        // this.ngZone.run(() => {
        //   //DO SOMETHING IF NEEDED
        // });
      }
    );
 
    PushNotifications.addListener('pushNotificationActionPerformed',
      async (notification: ActionPerformed) => {
        const data = notification.notification.data;
        console.log('Action performed: ' + JSON.stringify(notification.notification));
        if (data.path) {
          this.router.navigateByUrl(data.path);
        }
      }
    );
  }

  getDeliveredNotifications() {
    PushNotifications.getDeliveredNotifications().then(notificationList => {
      console.log('delivered notifications', notificationList);
    })
  }
  
  subscribeToTopic(topic: string) {
    FCM.subscribeTo({ topic: environment.fcm_topics_prefix + topic })
    .then((r) => {
      console.log('subscribed to topic', environment.fcm_topics_prefix + topic);
      // alert('Subscribed to topic: ' + environment.fcm_topics_prefix + topic);
    })
    .catch((err) => {
      console.log(err);
      // alert(JSON.stringify(err));
    });
  }

  unsubscribeFromTopic(topic: string) {
    FCM.unsubscribeFrom({ topic: environment.fcm_topics_prefix + topic })
    .then((r) => {
      console.log('unsubscribed from topic', environment.fcm_topics_prefix + topic);
      // alert('Unubscribed from topic: ' + environment.fcm_topics_prefix + topic);
    })
    .catch((err) => {
      console.log(err);
      // alert(JSON.stringify(err));
    });
  }


  //Notification settings

  setNotificationSettings(data: NotificationSetting) {
    this.http.post(apiURLs.root + apiURLs.setNotificationSettings, data).pipe(
      take(1)).subscribe((res: any) => {
        if (res.message == 'Success') {
          this.notificationSettings.next(data);
        }
      });
  }

  getNotificationSettings() {
    console.log('get notificatinos')
    this.http.get<NotificationSetting>(apiURLs.root + apiURLs.getNotificationSettings).pipe(
      take(1)).subscribe(res => {
        //setting observable with settings and initial topic subscription
        this.notificationSettings.next(res);
        if (this.platform.is('capacitor')) {
          this.allTopicsToggle('general', res.general);
          this.allTopicsToggle('reminders', res.reminders);
        }
      });
  }

  notificationSettingsState() {
    return this.notificationSettings.asObservable();
  }

  allTopicsToggle(setting: NotificationSettingType, checked: boolean) {
    switch (setting) {
      case 'general':
        this.myProfileService.getAccountInfo().pipe(take(1)).subscribe(dataAccount => {
          if (checked) {
            this.subscribeToTopic('allUsers');
            if (dataAccount.account.company_type == 'PLC') {
              this.subscribeToTopic('plcUsers');
            }
            if (dataAccount.account.company_type == 'Director Member') {
              this.subscribeToTopic('directorUsers');
              this.subscribeToTopic('umbrellaUsers');
            }
            if (dataAccount.account.company_type == 'PAYE Member') {
              this.subscribeToTopic('payeUsers');
              this.subscribeToTopic('umbrellaUsers');
            }
          } else {
            this.unsubscribeFromTopic('allUsers');
            this.unsubscribeFromTopic('plcUsers');
            this.unsubscribeFromTopic('directorUsers');
            this.unsubscribeFromTopic('umbrellaUsers');
            this.unsubscribeFromTopic('payeUsers');
          }
        });
        break;

      case 'reminders':
        this.myProfileService.getPersonalInfo().pipe(take(1)).subscribe(dataPersonal => {
          if (checked) {
            this.subscribeToTopic(dataPersonal.profile.unique_id.toString());
          } else {
            this.unsubscribeFromTopic(dataPersonal.profile.unique_id.toString());
          }
        })
        break;
    
      default:
        break;
    }
  }

  
}
